import React from 'react';
import closeIcon from '../../Assets/Icons/close.svg';
import { termsandcondition } from '../../Assets/term&condition';

const Popup = ({ onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 custom-scroll">
      <div className="bg-white rounded shadow-custom text-black w-[95vw] h-[min-content] border border-gray-600 relative">
        <div className="sticky top-0 bg-[#002746] text-white h-12 flex items-center justify-between px-2 shadow-custom z-10">
          <h1>Copyright & Terms</h1>
          <button onClick={onClose} aria-label="Close" className='rounded-full hover:bg-[#70809087]'>
            <img src={closeIcon} alt="close" className="w-8 h-8" />
          </button>
        </div>
        <div className="px-4 pt-4 h-[70vh] overflow-y-scroll custom-scrollbar">
          {termsandcondition.map((term) => (
            <div key={term.id} className="pt-6">
              <h6 className="text-[#002746]">{term.header}</h6>
              <section className='pt-6'>
                <p>{term.content}</p>
              </section>
            </div>
          ))}
        </div>
        <div className='sticky bottom-0 bg-white w-full flex justify-end p-2 rounded-b-[4px]' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px' }}>
          <button onClick={onClose} className="px-4 py-2 bg-[#002746] text-white rounded">
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
