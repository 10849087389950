import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ActiveButtonContext } from '../../Contexts/ActiveButtonContext';

const LeaderBoardCardSkeleton = () => {
  const { activeUrl } = useContext(ActiveButtonContext);
  const cardHeight = activeUrl === 'ui_repo' ? '100%' : 'min-content';

  return (
    <div className='flex flex-col border bg-white rounded-md shadow-custom w-[270px]' style={{ height: cardHeight }}>
      <div className='flex flex-row justify-between px-5 pt-4'>
        <Skeleton width={250} height={20} />
      </div>
      <div className='flex flex-row mt-2'>
        <div className='flex-1'>
          <Skeleton width="80%" height={20} className='ml-5' />
        </div>
        <div className='flex-1'>
          <Skeleton width="80%" height={20} className='ml-4' />
        </div>
      </div>
      <div className='px-5 pt-3 overflow-auto custom-scrollbar-hidden'>
        <Skeleton count={3} height={40} />
      </div>
      <div className='flex justify-center my-1'>
        <Skeleton width={60} height={20} />
      </div>
    </div>
  );
};

export default LeaderBoardCardSkeleton;
