import React from 'react';
import Logo from '../../../Assets/logo/logo.png';
import appStudioIcon from "../../../Assets/Icons/appStudioIcon.png";
import apiHubIcon from '../../../Assets/Icons/IconLeft.png';
import helpIcon from '../../../Assets/Icons/helpIcon.png';
import ButtonCard from './ButtonCard';

const SidePanel = () => {
  return (
    <div className='flex h-screen flex-col justify-between  bg-white text-black shadow-custom'>
      <div className='flex items-center justify-center pt-4 px-2 2xl:pt-4 projection:pt-12 2xl:text-[15px] projection:text-3xl'>
        <img src={Logo} alt='Logo' className='projection:h-[50px] h-[40px] mr-2' />
        <span><strong>Unified Dev Suite</strong></span>
      </div>
      <div className='flex flex-col items-start space-y-4 w-full'>
        <ButtonCard icon={appStudioIcon} label="UI Repo" url="ui_repo" />
        <ButtonCard icon={apiHubIcon} label="API Repo" url="api_repo" />
        <ButtonCard icon={helpIcon} label="Help" url="help"/>
      </div>
      <div className='text-center py-3'>
        <a href='https://www.ltts.com' target='new' className='text-[#002746] text-sm'>www.ltts.com</a>
      </div>
    </div>
  );
}

export default SidePanel;
