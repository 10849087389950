import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActiveButtonContext } from '../../../Contexts/ActiveButtonContext';

const ButtonCard = ({ icon, label, url }) => {
  const { activeButton, setActiveButton, setActiveUrl } = useContext(ActiveButtonContext);
  const navigate = useNavigate();

  const handleClick = () => {
    setActiveButton(label);
    setActiveUrl(url);
    navigate(`/dashboard/${url}`);
  };

  return (
    <div className="relative flex items-center w-full projection:text-3xl">
      <span className={`absolute left-0 w-[7px] h-full ${activeButton === label ? 'bg-[#002746] rounded-tr-lg rounded-br-lg' : ''}`}></span>
      <button 
        className={`flex items-center w-full space-x-2 p-2  pl-5 ${activeButton === label ? 'bg-[#EDF0F2]' : 'bg-white'}`} 
        onClick={handleClick}
      >
        <img src={icon} className='h-4 projection:h-8' alt='icon' />
        <span>{label}</span>
      </button>
    </div>
  );
}

export default ButtonCard;
