import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SidePanel from '../../Components/DashBoard/SidePanel/SidePanel';
import UpperPanel from '../../Components/DashBoard/UpperPanel/UpperPanel';
import { ActiveButtonContext } from '../../Contexts/ActiveButtonContext';
import Footer from '../../Components/Footer/Footer';
import AppStudio from '../../Components/AppStudio/AppStudio';
import ApiHub from '../../Components/ApiHub/ApiHub';
import Help from '../../Components/Help/Help';

const DashBoardPage = () => {
  const { activeButton, setActiveButton } = useContext(ActiveButtonContext);
  const { activeUrl } = useParams();
  
  useEffect(() => {
    switch (activeUrl) {
      case 'ui_repo':
        setActiveButton('UI Repo');
        break;
      case 'api_repo':
        setActiveButton('API Repo');
        break;
      case 'help':
        setActiveButton('Help');
        break;
      default:
        setActiveButton(null);
        ;
    }
  }, [activeUrl, setActiveButton]);

  const renderActiveComponent = () => {
    switch (activeButton) {
      case 'UI Repo':
        return <AppStudio />;
      case 'API Repo':
        return <ApiHub />;
      case 'Help':
        return <Help />;
      default:
        return null;
    }
  };

  return (
    <div className='flex flex-col w-full'>
      <div className='flex flex-row flex-grow'>
        <div className="sticky top-0 h-screen w-[13%]">
          <SidePanel />
        </div>
        <div className='flex flex-col flex-grow h-screen overflow-y-auto w-[87%]'>
          <UpperPanel />
          <div className='px-[8px] flex-grow -mt-10 flex flex-col '>
            {renderActiveComponent()}
          </div>
          <div className='sticky bottom-0 z-0'>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoardPage;
