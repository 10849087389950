import React, { useEffect, useRef } from 'react';
import closeIcon from '../../Assets/Icons/close.svg';
import userIcon from '../../Assets/Icons/userIcon.png';
import userRank from '../../Assets/Icons/LeaderBoard/userRank.png';
import Rank1 from '../../Assets/Icons/LeaderBoard/Rank1.png';
import Rank2 from '../../Assets/Icons/LeaderBoard/Rank2.png';
import Rank3 from '../../Assets/Icons/LeaderBoard/Rank3.png';
import { useMsal } from '@azure/msal-react';

const ContributorTable = ({ onClose, data }) => {
  const closeButtonRef = useRef(null);
  
  const{accounts}=useMsal();

  
  

  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  const trimName = (name) => {
    if (!name) return '';
    return name.split(' ').slice(0, 2).join(' ');
  };

  const top3 = data.slice(0, 3);
  const filteredData = data.slice(3);
  const Rank = data.find(item => item.email === accounts[0]?.username)?.rank || '0';


  return (
    <div className="fixed inset-0 flex pt-5 justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded shadow-custom text-black  w-[80vw] h-[min-content] border border-gray-600 relative z-20">
        <div className="sticky top-0 bg-[#002746] text-white h-12 flex items-center justify-between px-2 shadow-custom z-10">
          <h1>Leaderboard</h1>
          <button 
            onClick={onClose} 
            aria-label="Close" 
            className='rounded-full hover:bg-[#70809087]' 
            ref={closeButtonRef}
          >
            <img src={closeIcon} alt="close" className="w-8 h-8" />
          </button>
        </div>
        <div className="px-4  h-[73vh] ">
          <div className='sticky top-0 bg-white pt-4 pb-2'>
            <div className="flex justify-end items-center gap-2">
              <img src={userIcon} alt='user' className='w-10 h-10'/>
              <p>Your Rank</p>
              <p className='rounded-full bg-[#002746] text-white text-center w-8 h-8 leading-8'>{Rank}</p>
            </div>
            <div className="flex flex-row items-center justify-center mt-4">
              <div className="bg-cover bg-center w-[88px] h-[105px] rounded-lg relative bg-[#193c58]">
                <img src={userRank} alt='' className='absolute top-[-22px] left-1/2 transform -translate-x-1/2 w-[44px] h-[44px]' />
                <div className='flex flex-col items-center justify-center pt-[25px] h-full'>
                  <p className='flex-grow flex text-[11px] items-center justify-center font-bold text-white'>{trimName(top3[1]?.senderName)}</p>
                  <img src={Rank2} alt='rank' className='w-[22px] h-[22px] mb-2' />
                </div>
              </div>
              <div className="bg-cover bg-center w-[110px] h-[130px] rounded-lg relative bg-[#002746] z-10 ">
                <img src={userRank} alt='' className='absolute top-[-25px] left-1/2 transform -translate-x-1/2 w-[50px] h-[50px]' />
                <div className='flex flex-col items-center justify-center pt-[25px] h-full'>
                  <p className='flex-grow flex text-[11px] items-center justify-center font-bold text-white'>{trimName(top3[0]?.senderName)}</p>
                  <img src={Rank1} alt='rank' className='w-[48px] h-[48px] mb-2' />
                </div>
              </div>
              <div className="bg-cover bg-center w-[88px] h-[105px] rounded-lg relative bg-[#193c58]">
                <img src={userRank} alt='' className='absolute top-[-22px] left-1/2 transform -translate-x-1/2 w-[44px] h-[44px]' />
                <div className='flex flex-col items-center justify-center pt-[25px] h-full'>
                  <p className='flex-grow flex text-[11px] items-center justify-center font-bold text-white'>{trimName(top3[2]?.senderName)}</p>
                  <img src={Rank3} alt='rank' className='w-[22px] h-[22px] mb-2' />
                </div>
              </div>
            </div>
          </div>
            <div className="mt-4  h-[50%] 2xl:h-[66%] overflow-y-auto">
              <table className="w-full">
                  <thead className="bg-[#002746] border-b border-gray-200 text-white">
                      <tr className="font-bold table-row bg-[#002746] border-b sticky top-[0px] border-gray-200">
                          <th className="text-left p-2">Rank</th>
                          <th className=" text-left p-2">Name</th>
                          <th className=" text-left p-2">Email</th>
                          <th className=" text-left p-2">Component count</th>
                      </tr>
                  </thead>
                  
                  <tbody className="pt-[35px] h-[50%] overflow-y-auto">
                      {filteredData.map((user, index) => (
                          <tr key={index} className="border-b border-gray-200">
                              <td className="text-left p-2">{user.rank}</td>
                              <td className="text-left p-2">{user.senderName}</td>
                              <td className="text-left p-2">{user.email}</td>
                              <td className="text-left p-2">{user.publishedCount}</td>
                          </tr>
                      ))}
                  </tbody>
                  
              </table>
          </div>

            
        </div>
        <div className='sticky bottom-0 bg-white w-full flex justify-end p-2 rounded-b-[4px] gap-3' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px' }}>
          
          <button onClick={onClose} className="px-4 py-2 bg-[#002746]  border-2 text-white rounded-md">
           OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContributorTable;
