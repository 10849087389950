import React from 'react';
import ApexCharts from 'react-apexcharts';
import useComponentData from '../../../API/useComponentData';

const ReactAngularComponentCountGraph = () => {
    const { loading, error, reactComponentCountData, angularComponentCountData } = useComponentData();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading data</div>;
    }

    const getCount = (data, category) => {
        const item = data.find(item => 
            (item.category && item.category === category) ||
            (item.Category && item.Category === category)
        );
        return item ? item.count : 0;
    };

    const series = [
        {
            name: 'Angular',
            data: [
                getCount(angularComponentCountData, 'forms'),
                getCount(angularComponentCountData, 'graph/charts'),
                getCount(angularComponentCountData, 'media_view'),
                getCount(angularComponentCountData, 'resting_display'),
                getCount(angularComponentCountData, 'navigation'),
                getCount(angularComponentCountData, 'Generic_UI'),
                getCount(angularComponentCountData, 'Authentication'),
                getCount(angularComponentCountData, 'Project_Structure')
            ]
        },
        {
            name: 'React',
            data: [
                getCount(reactComponentCountData, 'Forms'),
                getCount(reactComponentCountData, 'Graph_Charts'),
                getCount(reactComponentCountData, 'Media_View'),
                getCount(reactComponentCountData, 'Resting_Display'),
                getCount(reactComponentCountData, 'Navigation'),
                getCount(reactComponentCountData, 'Generic_UI'),
                getCount(reactComponentCountData, 'Authentication'),
                getCount(reactComponentCountData, 'Project_Strucure')
            ]
        }
    ];

    const options = {
        chart: {
            type: 'bar',
            height: '100%',
            width: '100%',
            toolbar: {
                show: false // Disable the toolbar
            }
        },
        plotOptions: {
            bar: {
                horizontal: true
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: [
                "forms", "graph/charts", "media_view", "resting_display", 
                "navigation", "generic_ui", "authentication", "project_structure"
            ]
        },
        colors: ['#002746', '#2DA2F9'],
        tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                const category = w.globals.labels[dataPointIndex];
                const value = series[seriesIndex][dataPointIndex];
                const legendColor = w.config.colors[seriesIndex];
                const legendName = w.config.series[seriesIndex].name;

                return `<div style="padding: 10px; border-radius: 5px; background: rgba(0, 0, 0, 0.8); color:white; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
                            <p style="margin: 0; font-weight: bold;">${category}: ${value}</p>
                            <p style="margin: 0;">
                                <span style="display: inline-block; width: 10px; height: 10px; background: ${legendColor}; margin-right: 5px;"></span>
                                ${legendName}: ${value}
                            </p>
                        </div>`;
            }
        }
    };

    const containerStyle = {
        height: '100%', 
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const chartContainerStyle = {
        height: '100%',
        width: '100%'
    };

    return (
        <div style={containerStyle}>
            <div style={chartContainerStyle}>
                <ApexCharts options={options} series={series} type="bar" height="100%" width="100%" />
            </div>
        </div>
    );
};

export default ReactAngularComponentCountGraph;
