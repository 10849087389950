const msalConfig = {
  auth: {
    clientId: '8ae91ebc-1bf8-48a3-b0a0-926953f078cc',
    authority:'https://login.microsoftonline.com/311b3378-8e8a-4b5e-a33f-e80a3d8ba60a',
    redirectUri: 'https://udscommunitymain-cdarcna6cdcsgzan.southindia-01.azurewebsites.net/dashboard/ui_repo',
    // clientId: "f9d0e101-7342-42e8-8b1e-090941e6e4b3",
    // authority: 'https://login.microsoftonline.com/df7b3572-e484-4fcf-a072-6edd5d73f11f',
    // redirectUri: 'http://localhost:3000/dashboard/ui_repo',
    postLogoutRedirectUri: "https://udscommunitymain-cdarcna6cdcsgzan.southindia-01.azurewebsites.net",
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};

export default msalConfig