import React from 'react';
import Chart from 'react-apexcharts';
import useComponentCountData from '../../../API/useComponentCountData';

const ReactAngularComponentCountDoughnutGraph = () => {
  const { loading, reactAngularComponentCountData } = useComponentCountData();

  if (loading) {
    return <div>Loading...</div>;
  }

  const labels = reactAngularComponentCountData.map((item) => item.Technology);
  const ComponentCount = reactAngularComponentCountData.map((item) => item.count);

  const options = {
    chart: {
      type: 'donut',
    },
    labels: labels,
    colors: ['#002746', '#2DA2F9'],
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff'],
        fontWeight: 'bold',
        fontSize: '16px'
      },
      formatter: function (val, opts) {
        return opts.w.globals.series[opts.seriesIndex];
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    legend: {
      markers: {
        shape: 'square', // Change the shape to square (rectangle)
      },
    },
  };

  const series = ComponentCount;

  return (
    <div id="chart">
      <Chart options={options} series={series} type="donut" height={300} />
    </div>
  );
};

export default ReactAngularComponentCountDoughnutGraph;
