import React from 'react';
import Chart from 'react-apexcharts';
import useEachComponentCountData from '../../../API/useEachComponentCountData';



const ReactAngularRadarGraph = () => {
    const { loading, reactAngularComponentCountData } = useEachComponentCountData();

    if (loading) {
        return <div>Loading...</div>;
    }

    const labels = reactAngularComponentCountData.map((item)=>{
      return `${item.Category}-${item.Technology}`
    });

    const componetCount = reactAngularComponentCountData.map((item)=>item.totalCount);


  const options = {
    chart: {
      type: 'polarArea',
    },
    labels: labels,
    stroke: {
      colors: ['#fff'],
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: 'dark',
        shadeIntensity: 0.9
      }
    },
    fill: {
      opacity: 0.8,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 400,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
    legend: {
      markers: {
        shape: 'square', // Change the shape to square (rectangle)
      },
    },
  };

  const series = componetCount;

  return (
    <div id="chart">
      <Chart options={options} series={series} type="polarArea" height={300}  />
    </div>
  );
};

export default ReactAngularRadarGraph;
