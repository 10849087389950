import React from 'react';
import Card from './Card'; 
import CardSkeleton from './CardSkeleton';
import { ApiHubCardData } from './ApiHubCardData';
import GlobalCard from '../../GlobalCard/GlobalCard';
import { ApiHubTotalData } from './ApiHubTotalData';
import LeaderBoard from './LeaderBoard';
import LeaderBoardDataProvider from '../../../Contexts/LeaderBoardDataContext';

const Cards = () => {
  const apiHubTotalData = ApiHubTotalData();
  const apiHubCardData = ApiHubCardData();

  return (
    <div className='flex flex-row gap-[8px] w-full flex-grow h-[45vh] '>
      <div className='w-[75%] h-full'>
        <div className='flex flex-col gap-[8px] h-full '>
          <div className="flex flex-wrap w-full h-[40%] ">
             <GlobalCard data={apiHubTotalData} /> 
            
          </div>
          <div className="flex  gap-[8px] h-[70%]">
            {apiHubCardData.length > 0 ? (
              apiHubCardData.map((data, index) => (
                <Card key={index} data={data} />
              ))
            ) : (
              [...Array(2)].map((_, index) => (
                <CardSkeleton key={index} />
              ))
            )}
          </div>
        </div>
      </div>
      <div className='w-[25%]'>
      <LeaderBoardDataProvider>
        <LeaderBoard />
      </LeaderBoardDataProvider>
      </div>
    </div>
  );
};

export default Cards;
