import React, { useState } from 'react';
import PublishedComponentList from '../AppStudio/PublishedComponentList';
import closeIcon from "../../Assets/Icons/close.svg"
import usePublishedComponent from '../../API/usePublishedComponent';

const GlobalCard = ({ data }) => {
  const { loading, publishedComponent } = usePublishedComponent();
  const [showPopup, setShowPopup] = useState(false);

  const handleClick = (title) => {
    if (title === 'Total Components') {
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className='flex flex-row gap-[8px] w-full'>
      {data.map((item, index) => (
        <div
          key={index}
          className={`text-center justify-between  flex flex-row border items-center bg-white rounded-lg shadow-custom p-7  ${item.title === 'Total Component' ? 'cursor-pointer hover:transform hover:scale-[1.01] transition-transform duration-300' : ''}`}
          onClick={() => handleClick(item.title)}
          style={{ flex: 1 }}
        >
          <div className="truncate">
            <strong className='md:text-[0.5rem] lg:text-[1rem]'>{item.title}</strong>
          </div>
          <div className='text-[#0479D0] truncate'>
            <strong className='md:text-[1.5rem] lg:text-[2rem]'>{item.count}</strong>
          </div>
        </div>
      ))}

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white rounded-lg shadow-custom w-[50vw]">
            <div className='bg-[#002746] flex flex-row justify-between p-4'>
              <h2 className="text-xl font-bold  text-white">Total Component</h2>
              <button onClick={closePopup} className=''><img className='w-5 h-5' src={closeIcon} alt='close' /></button>
            </div>
            <div className='p-4'>
              <PublishedComponentList loading={loading} publishedComponent={publishedComponent}/>
            </div>
            <div className='flex items-center justify-end p-2'>
              <button onClick={closePopup} className=" bg-[#002746] px-4 py-2 text-white rounded">Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalCard;
