import React from 'react';

const Card = ({ data }) => {
  return (
    <div className="border bg-white rounded-lg" style={{flex:1}}>
      <div className='shadow-custom rounded-lg h-full'>
        <div className='flex justify-between py-6 px-4 shadow-md items-center h-[50%]'>
          <div className="font-bold text-[16px] text-gray-800">{data.Title}</div>
          <div className="text-[1.5rem] text-[#0479D0]"><strong>{data.TitleValue}</strong></div>
        </div>
        <div className='flex flex-row items-center justify-between py-6 px-4 rounded-b-lg h-[50%]'>
          <table className='w-full'>
            <thead>
              <tr className='flex flex-row'>
                {data.state.map((card, index) => (
                  <th 
                    key={index} 
                    className={`w-full ${index !== data.state.length - 1 ? 'border-r border-black' : ''}`}
                  >
                    <div className='flex flex-col justify-between'>
                      <div className="text-[#1F1F1FF2] text-[11px] text-center"><strong>{card.Title}</strong></div>
                      <div className="text-[#0479D0] text-[11px] text-center"><strong>{card.Value}</strong></div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Card;
